import useSWR from 'swr'

import { FilterType, SearchFilterType, ToolsResponse } from 'types/types'

type Props = {
  searchValueFilter?: SearchFilterType
  searchValue?: string
  selectedFilters?: FilterType[]
  top?: number
  skip?: number
}

const useTools = ({
  searchValueFilter,
  searchValue,
  selectedFilters,
  top = 20,
  skip = 0
}: Props) => {
  const queryItems = []
  if (searchValueFilter && searchValue && searchValue?.length > 0) {
    if (searchValueFilter === 'name') {
      queryItems.push(`name=${searchValue}`)
    }
    if (searchValueFilter === 'url') {
      queryItems.push(`url=${searchValue}`)
    }
    if (searchValueFilter === 'description') {
      queryItems.push(`description=${searchValue}`)
    }
    if (searchValueFilter === 'departments') {
      queryItems.push(`departments=${searchValue}`)
    }
    if (searchValueFilter === 'owners') {
      queryItems.push(`owners=${searchValue}`)
    }
  }

  if (selectedFilters) {
    selectedFilters.forEach(filter => {
      queryItems.push(`${filter}=true`)
    })
  }

  const pagination = []
  if (top > 0) pagination.push(`top=${top}`)
  if (skip > 0) pagination.push(`skip=${skip}`)

  const query =
    queryItems.join('&') +
    (queryItems.length > 0 && pagination.length > 0 ? '&' : '') +
    (pagination.length > 0 ? pagination.join('&') : '')

  const { data, error, isValidating, isLoading, mutate } =
    useSWR<ToolsResponse>(
      query
        ? `tools?${query}`
        : `${pagination.length > 0 ? 'tools?' + pagination : 'tools'}`
    )

  return {
    data: data?.value,
    count: data?.count,
    error,
    isValidating,
    isLoading,
    mutate
  }
}

export default useTools
