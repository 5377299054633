import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/pro-regular-svg-icons'
import { Checkbox, Nav, Theme, useApplyTheme } from '@intility/bifrost-react'
import useLocalStorageState from 'use-local-storage-state'

import { logout } from 'auth/instance'
import ProfilePicture from './ProfilePicture'

const Header = () => {
  // persist theme state in local storage.
  // you might want to use a cookie or a database instead?
  const [theme, setTheme] = useLocalStorageState<Theme>('bfTheme', {
    defaultValue: 'system'
  })
  // keep document theme in sync with state
  useApplyTheme(theme)

  return (
    <Nav.Group name={<ProfilePicture />}>
      <section>
        <Nav.Header>Color theme</Nav.Header>
        <Checkbox
          type='radio'
          label='Dark'
          name='color-theme'
          checked={theme === 'dark'}
          onChange={() => setTheme('dark')}
        />
        <Checkbox
          type='radio'
          label='Light'
          name='color-theme'
          checked={theme === 'light'}
          onChange={() => setTheme('light')}
        />
        <Checkbox
          type='radio'
          label='System'
          name='color-theme'
          checked={theme === 'system'}
          onChange={() => setTheme('system')}
        />
      </section>
      <section>
        <Nav.Header>Feedback</Nav.Header>
        <a
          href='https://teams.microsoft.com/l/channel/19%3a49fdd815660d4287b54808c90da08c56%40thread.skype/Portal%2520and%2520User%2520Experience?groupId=78c06d91-45ea-4b22-8462-884cba69657b&tenantId=9b5ff18e-53c0-45a2-8bc2-9c0c8f60b2c6'
          className='bf-neutral-link'
          target='_blank'
          rel='noreferrer'
        >
          <Nav.Item icon={faUsers} hideCircle>
            Teams
          </Nav.Item>
        </a>
      </section>
      <hr />
      <button onClick={() => logout()}>
        <Nav.Item icon={faSignOutAlt} hideCircle>
          Log out
        </Nav.Item>
      </button>
    </Nav.Group>
  )
}

export default Header
