import { Button, Grid, Inline, Modal } from '@intility/bifrost-react'

import useTools from 'api/useTools'
import { authorizedFetch } from 'auth'
import { ToolType } from 'types/types'

type DeleteToolModalProps = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  tool?: ToolType
}

const DeleteToolModal = ({ show, setShow, tool }: DeleteToolModalProps) => {
  const { mutate: mutateTools } = useTools({})
  const { mutate: mutateStarred } = useTools({ selectedFilters: ['starred'] })

  // Deleting Tool
  const deleteTool = async () => {
    if (!tool) return
    try {
      const res = await authorizedFetch(`tools/${tool.id}`, {
        method: 'DELETE'
      })
      if (!res.ok) {
        throw new Error('Bad response')
      }
      mutateTools()
      mutateStarred()
      setShow(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => setShow(false)}
      header='Delete the service?'
    >
      <Grid gap={24} style={{ maxWidth: '500px' }}>
        <p className='bf-p'>
          This action will remove <i>{tool?.name}</i> from Intility Tools. Are
          you sure?
        </p>

        <Inline>
          <Button type='button' onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Inline.Separator />
          <Button
            type='button'
            state='alert'
            variant='filled'
            onClick={() => deleteTool()}
          >
            Delete
          </Button>
        </Inline>
      </Grid>
    </Modal>
  )
}

export default DeleteToolModal
