import { Button, Icon, Skeleton } from '@intility/bifrost-react'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import './Card.css'

const SkeletonCard = () => {
  return (
    <div className='starred-card'>
      <div className='card-header'>
        <Icon icon={faStar} />
        <Skeleton.Text width={'60%'} />
      </div>
      <p className='card-description'>
        <Skeleton.Text />
        <Skeleton.Text />
      </p>

      <div className='card-footer'>
        <Button variant='flat' className='details-btn'>
          View details
        </Button>
        <Button variant='flat' className='visit-btn'>
          Visit page
        </Button>
      </div>
    </div>
  )
}

export default SkeletonCard
