import { Bifrost, enUS } from '@intility/bifrost-react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { SWRConfig } from 'swr'

import { router } from 'router'
import { initializeSentry } from 'utils/initializeSentry'
// https://bifrost.intility.com/#/Welcome%20to%20Bifrost/Get%20started/Installation
import '@intility/bifrost-react/dist/bifrost-app.css'
import Authentication from 'auth/Authentication'
import { defaultFetcher } from 'utils/fetchers'

const rootElement = document.getElementById('root')!
const root = createRoot(rootElement)

const fetcher = defaultFetcher

root.render(
  <StrictMode>
    <Authentication>
      <SWRConfig value={{ fetcher }}>
        <Bifrost locale={enUS}>
          <RouterProvider router={router} />
        </Bifrost>
      </SWRConfig>
    </Authentication>
  </StrictMode>
)

initializeSentry()
