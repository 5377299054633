import { Skeleton, Table } from '@intility/bifrost-react'

import tableFormatter from '../../../utils/TableFormatter'

const SkeletonTable = () => {
  return (
    <Table className='skeleton-table'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          {tableFormatter.map(title => (
            <Table.HeaderCell key={title.key} className={`from-${title.size}`}>
              {title.name}
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Skeleton repeat={20}>
          <Table.Row>
            <Table.Cell width='5%'></Table.Cell>
            {tableFormatter.map(title => {
              return (
                <Table.Cell key={title.key} className={`from-${title.size}`}>
                  <Skeleton.Text />
                </Table.Cell>
              )
            })}
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Skeleton>
      </Table.Body>
    </Table>
  )
}

export default SkeletonTable
