import { Label } from '@intility/bifrost-react'

import { OwnerType, ToolType } from 'types/types'
import OwnerPicture from '../OwnerPicture'
import './ToolsTable.css'

export const OwnerPictureSpan = ({ owner }: { owner: OwnerType }) => {
  return (
    <span>
      <OwnerPicture employee={owner} /> {owner.fullName}
    </span>
  )
}

const ExpandContent = ({ tool }: { tool: ToolType }) => {
  return (
    <div className='table-row-content'>
      <div className='to-medium'>
        <Label>Description</Label>
        {tool?.description}
      </div>

      <div className='to-small'>
        <Label>Departments</Label>
        {tool?.departments ?? ''}
      </div>

      {tool.secondaryUrls &&
        tool.secondaryUrls.map(x => {
          if (x.url.includes('https://')) {
            return (
              <div key={x.url}>
                <Label> {x.type} </Label>
                <a
                  className='bf-link'
                  href={x.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  {x.url}
                </a>
              </div>
            )
          }
          return (
            <div key={x.url}>
              <Label> {x.type} </Label>
              {x.url}
            </div>
          )
        })}

      <div>
        <div>
          <Label> Product owners </Label>
          <div className='row-content-owners'>
            {tool?.productOwners.map(x => (
              <OwnerPictureSpan key={x.id} owner={x} />
            ))}
          </div>
        </div>

        {tool.technicalOwners && tool.technicalOwners.length > 0 && (
          <div>
            <Label> Technical owners </Label>
            <div className='row-content-owners'>
              {tool.technicalOwners.map(x => (
                <OwnerPictureSpan key={x.id} owner={x} />
              ))}
            </div>
          </div>
        )}

        {tool.designOwners && tool.designOwners.length > 0 && (
          <div>
            <Label> Design owners </Label>
            <div className='row-content-owners'>
              {tool.designOwners.map(x => (
                <OwnerPictureSpan key={x.id} owner={x} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpandContent
