import { Nav } from '@intility/bifrost-react'
import { NavLink } from 'react-router-dom'

import Header from './Header/Header'

const Navigation = ({ children }: React.PropsWithChildren) => {
  return (
    <Nav
      logo={
        <NavLink to='/' className='bf-neutral-link'>
          <Nav.Logo logo='/logo192.png'>Intility Tools</Nav.Logo>
        </NavLink>
      }
      top={<Header />}
    >
      {children}
    </Nav>
  )
}

export default Navigation
