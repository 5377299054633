import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { Icon, Skeleton } from '@intility/bifrost-react'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

import useEmployeePicture from 'api/useEmployeePicture'
import { EmployeeType } from 'types/types'
import styles from './OwnerPicture.module.css'

const OwnerPicture = ({
  employee,
  size = 24
}: {
  employee: EmployeeType
  size?: number
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  const { data, isValidating, error } = useEmployeePicture(
    inView ? employee.id : undefined
  )

  if (!data && !error && isValidating) {
    return (
      <Skeleton.Rect
        className={styles.ownerImage}
        style={{ width: size + 'px', height: size + 'px' }}
      />
    )
  }

  if ((!data && !isValidating) || error) {
    return (
      <div
        ref={ref}
        className={classNames(styles.ownerImage, styles.fallbackImage)}
        style={{ width: size + 'px', height: size + 'px' }}
      >
        <Icon
          icon={faUser}
          style={{ width: size - 10 + 'px', height: size - 10 + 'px' }}
        />
      </div>
    )
  }

  return (
    <img
      src={data}
      alt={''}
      className={styles.ownerImage}
      style={{ width: size + 'px', height: size + 'px' }}
    />
  )
}

export default OwnerPicture
