import { browserTracingIntegration, init } from '@sentry/react'

/**
 * Initializes sentry if the application is built for production.
 * @see https://docs.sentry.io/platforms/javascript/guides/react
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
function initializeSentry() {
  if (process.env.NODE_ENV !== 'development') {
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      integrations: [browserTracingIntegration()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.5
    })
  }
}

export { initializeSentry }
