import { useAccount } from '@azure/msal-react'
import { faPen, faStar } from '@fortawesome/pro-light-svg-icons'
import { faStar as faStarSelected } from '@fortawesome/pro-solid-svg-icons'
import { Icon, Table } from '@intility/bifrost-react'
import { useEffect, useState } from 'react'

import useTools from 'api/useTools'
import { authorizedFetch } from 'auth'
import { ToolType } from 'types/types'
import tableFormatter from 'utils/TableFormatter'
import ExpandContent from './ExpandContent'

type TableRowProps = {
  tool: ToolType
  setSelectedTool: React.Dispatch<React.SetStateAction<ToolType | undefined>>
  setEditDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TableRow = ({
  tool,
  setSelectedTool,
  setEditDrawerOpen
}: TableRowProps) => {
  const user = useAccount()
  const [saving, setSaving] = useState(false)
  const [starred, setStarred] = useState(false)
  const { mutate: mutateStarred } = useTools({ selectedFilters: ['starred'] })
  const { mutate: mutateTools } = useTools({})

  useEffect(() => {
    if (!tool || !user) return
    const userId = user.homeAccountId.split('.')[0]
    if (tool.starred?.includes(userId)) {
      setStarred(true)
    }
  }, [tool, user])

  const handleSetStarred = () => {
    if (!user) return
    const userId = user.homeAccountId.split('.')[0]
    let newStarred = []
    if (tool.starred?.includes(userId)) {
      newStarred = tool.starred.filter(x => x !== userId)
    } else {
      newStarred = tool.starred ? [...tool.starred, userId] : [userId]
    }
    const updatedTool = { ...tool, starred: newStarred }
    updateService(updatedTool)
  }

  const updateService = async (updatedTool: ToolType) => {
    if (!updatedTool.id) {
      throw new Error('Missing ID on service')
    }
    try {
      setSaving(true)
      const res = await authorizedFetch(`tools/${updatedTool.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedTool)
      })
      if (!res.ok) throw new Error('Could not update service')
      setStarred(x => !x)
      mutateStarred()
      mutateTools()
    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Table.Row content={<ExpandContent tool={tool} />} limitExpandClick>
      <Table.Cell className='starred-icon-cell'>
        <Icon
          onClick={() => !saving && handleSetStarred()}
          icon={starred ? faStarSelected : faStar}
        />
      </Table.Cell>

      {tableFormatter.map(title => {
        return (
          <Table.Cell
            key={title.key}
            className={`from-${title.size} cell-type-${title.key}`}
          >
            {title.formatter
              ? title.formatter(tool)
              : (tool[title.key as keyof ToolType] as string)}
          </Table.Cell>
        )
      })}

      <Table.Cell>
        <button
          className='edit-tool-icon'
          tabIndex={0}
          onClick={() => {
            setSelectedTool(tool)
            setEditDrawerOpen(true)
          }}
        >
          <Icon icon={faPen} />
        </button>
      </Table.Cell>
    </Table.Row>
  )
}

export default TableRow
