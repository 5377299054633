import {
  InteractionRequiredAuthError,
  InteractionType
} from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsalAuthentication
} from '@azure/msal-react'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { Button, Icon } from '@intility/bifrost-react'
import { PropsWithChildren, useEffect } from 'react'

import { loginScope } from './config'
import { HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY } from './constants'
import { instance, loginHint } from './instance'

const loginRequest = {
  scopes: [loginScope],
  loginHint: loginHint
}

// only retrieved once on page load
const hasTriedInteractive = sessionStorage.getItem(
  HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY
)

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#ssosilent-example
const Template = ({ children }: PropsWithChildren) => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  )

  // if error is InteractionRequired, we will automatically try an interactive login
  // only try it automatically once per session
  const shouldTryInteractiveLogin =
    error instanceof InteractionRequiredAuthError && !hasTriedInteractive

  useEffect(() => {
    if (shouldTryInteractiveLogin) {
      login(InteractionType.Redirect, loginRequest)

      // will update hasTriedInteractive once the user is redirected back and load the page again
      sessionStorage.setItem(HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY, 'true')
    }
  }, [shouldTryInteractiveLogin, login])

  // only show error if we're not going to instantly redirect the user
  if (error && !shouldTryInteractiveLogin) {
    return (
      <div>
        <p>{error?.errorMessage}</p>
        <p>CorrelationId: {error?.correlationId}</p>
        <Button
          style={{ marginBottom: 12 }}
          state='neutral'
          pill
          onClick={() => login(InteractionType.Redirect, loginRequest)}
        >
          <Icon icon={faArrowsRotate} marginRight />
          Try again
        </Button>
      </div>
    )
  }

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
}

const Authentication = ({ children }: PropsWithChildren) => {
  return (
    <MsalProvider instance={instance}>
      <Template>{children}</Template>
    </MsalProvider>
  )
}

export default Authentication
