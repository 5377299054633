import { Badge, Tooltip } from '@intility/bifrost-react'

import { ToolType } from 'types/types'
import OwnerPicture from '../components/Home/OwnerPicture'

const tableFormatter = [
  {
    name: 'Service',
    key: 'name',
    size: 'null',
    formatter: (tool: ToolType) => {
      if (tool.inDevelopment) {
        return (
          <div>
            <span>{tool.name}</span>
            <Badge> Unreleased </Badge>
          </div>
        )
      }
      return tool.name
    }
  },
  {
    name: 'URL',
    key: 'url',
    size: 'null',
    formatter: (tool: ToolType) => {
      let url = tool.url
      if (tool.url.includes('https://')) {
        url = tool.url.split('https://')[1]
        return (
          <div className='table-cell-url'>
            <a
              className='bf-link table-url'
              href={tool.url}
              target='_blank'
              rel='noreferrer'
            >
              {url}
            </a>
            {tool.secondaryUrls && tool.secondaryUrls.length > 0 && (
              <span className='secondaryUrlsLength'>
                {' '}
                (+{tool.secondaryUrls.length})
              </span>
            )}
          </div>
        )
      } else {
        return (
          <div className='table-cell-url'>
            <span className='table-url'>{url}</span>
            {tool.secondaryUrls && tool.secondaryUrls.length > 0 && (
              <span className='secondaryUrlsLength'>
                {' '}
                (+{tool.secondaryUrls.length})
              </span>
            )}
          </div>
        )
      }
    }
  },
  { name: 'Description', key: 'description', size: 'medium' },
  {
    name: 'Departments',
    key: 'departments',
    size: 'small',
    formatter: (tool: ToolType) => {
      const input = tool.departments
      let deps = ''
      input.forEach((x, i) => {
        if (i === 0) {
          deps += x
        } else {
          deps += ', ' + x
        }
      })
      return deps
    }
  },
  {
    name: 'Owners',
    key: 'owners',
    size: 'medium',
    formatter: (tool: ToolType) => {
      const input = tool.owners
      return (
        <div className='owners-container'>
          {input.map(x => {
            return (
              <Tooltip key={x.fullName} content={x.fullName} state='neutral'>
                <span>
                  <OwnerPicture employee={x} />
                </span>
              </Tooltip>
            )
          })}
        </div>
      )
    }
  }
]

export default tableFormatter
