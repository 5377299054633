import {
  AuthenticationResult,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser'

import { config } from './config'
import {
  HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY,
  LOGIN_HINT_KEY
} from './constants'

// only get once on page load
export const loginHint = localStorage?.getItem(LOGIN_HINT_KEY) ?? undefined

/**
 * A PublicClientApplication instance
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const instance = new PublicClientApplication(config)

/**
 * Register event callback to set the active account atfer successful login
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/accounts.md#active-account-apis
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
 */
instance.addEventCallback(message => {
  if (
    message.eventType === EventType.LOGIN_SUCCESS ||
    message.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    const result = message.payload as AuthenticationResult
    if (!result.account) return

    instance.setActiveAccount(result.account)
    localStorage.setItem(LOGIN_HINT_KEY, result.account.username)
    sessionStorage.removeItem(HAS_TRIED_INTERACTIVE_LOGIN_STORAGE_KEY)
  }
})

export const logout = () => {
  instance.logoutRedirect()
}

export { instance }
