import { CustomErrorType } from 'types/types'
import './ErrorPage.css'

const ErrorPage = ({ error }: { error: CustomErrorType }) => {
  console.log(error)

  if (error instanceof TypeError) {
    return (
      <div className='error-page'>
        <h1 className='bf-h1'>Ooops, an error has occured!</h1>
        {error?.message && (
          <p className='bf-p'>
            {error.name}: {error.message}
          </p>
        )}
      </div>
    )
  }

  return (
    <div className='error-page'>
      {error?.status && <h5 className='bf-h5'> ERRORCODE: {error.status} </h5>}
      <h1 className='bf-h1'>Ooops, an error has occured!</h1>
      {error?.info && <p className='bf-p'> {error.info as string}</p>}
    </div>
  )
}

export default ErrorPage
